/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/scss/bootstrap-reboot";
@import "~bootstrap/scss/bootstrap-grid";

@import "./theme/theme.scss";

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

@import "../node_modules/c3/c3.min.css";

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("Open-Sans-Regular"),
    url("assets/fonts/OpenSans-Regular-webfont.woff") format("woff"),
    url("assets/fonts/OpenSans-Regular-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans Semibold";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Semibold"), local("Open-Sans-Semibold"),
    url("assets/fonts/OpenSans-Semibold-webfont.woff") format("woff"),
    url("assets/fonts/OpenSans-Semibold-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("Open-Sans-Bold"),
    url("assets/fonts/OpenSans-Bold-webfont.woff") format("woff"),
    url("assets/fonts/OpenSans-Bold-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(assets/fonts/MaterialIcons-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "schoolScreenerFont";
  src: url("assets/fonts/SchoolScreener.ttf") format("truetype");
}


@font-face {
  font-family: 'Frutiger';
  src: url('assets/fonts/Frutiger.ttf') format('truetype');
}

.preview-content {
  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-justify {
    text-align: justify;
  }

  img {
    max-width: 100%;
  }
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

html,
app-root {
  height: 100%;
}

body {
  background-color: #eaeaea;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  margin: 0;
  padding-top: 50px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

app-root {
  display: flex;
  min-height: -webkit-calc(100vh - 50px);
  min-height: -moz-calc(100vh - 50px);
  min-height: calc(100vh - 50px);
  flex-direction: column;
}


app-view-last-report,
print-queue {
  flex: 1;
  display: flex;
}

print-queue {
  height: calc(100vh - 124px);
}

app-view-last-report {
  min-height: calc(100vh - 124px);
}

@media only screen and (min-width: 768px) {
  app-children-page-screener {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }
}

@media only screen and (min-width: 768px) {
  admin-children-import {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }
}

@media only screen and (min-width: 768px) {
  admin-schools {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }
}


@media only screen and (min-width: 768px) {
  admin-users {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }
}

@media only screen and (min-width: 768px) {
  app-session-register {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }
}

@media only screen and (max-width: 768px) {
  app-session-register {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }
}

@media only screen and (min-width: 768px) {
  app-booking-manager-page {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }
}

@media only screen and (min-width: 768px) {
  app-interactive-test {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }

}

@media only screen and (min-width: 768px) {
  app-keeler-test {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }

}

@media only screen and (min-width: 768px) {
  app-manual-test {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }

}

@media only screen and (min-width: 768px) {
  app-growth-test-page {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }

}

@media only screen and (min-width: 768px) {
  app-generic-landing-page {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }

}

@media only screen and (min-width: 768px) {
  app-mfa-login-page {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }

}

@media only screen and (min-width: 768px) {
  app-forms-page {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }
}

@media only screen and (min-width: 768px) {
  app-parent-inbox {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }
}

@media only screen and (min-width: 768px) {
  admin-kpi {
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
  }

}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-v {
  margin: 0;
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.snackbar-action-white-text button {
  color: #ffffff;
}

.snackbar-alert {
  color: #ffffff;
  background-color: red;

  .mat-button {
    color: red;
    background-color: white;
  }
}

.snackbar-success {
  color: #ffffff;
  background-color: forestgreen;

  .mat-button {
    color: forestgreen;
    background-color: white;
  }
}

.full-height-page {
  flex: 1;
  display: flex;
}

.hyperlink-pipe,
.hyperlink-pipe:hover {
  color: #ffffff;
  text-decoration: underline;
}

.mat-radio-button>.mat-radio-label>.mat-radio-label-content {
  white-space: normal;
  word-break: break-word;
}

.description-question-link,
.description-question-link:hover {
  color: rgba(0, 0, 0, 0.87);
}

.warning-dialog>.mat-dialog-container {
  padding: 0;
}

.tabs-size .mat-tab-label,
.tabs-size .mat-tab-label-active {
  min-width: 25px !important;
}

.compare-children-dialog>.mat-dialog-container {
  overflow: unset;
  padding: 0;
}

@media (max-width: 767px) {
  .tabs-size .mat-tab-label {
    padding: 0;
  }
}

.ez-table-container .mat-header-cell {
  .mat-select-arrow-wrapper .mat-select-arrow {
    color: white;
  }

  .mat-select-value {
    color: white;
  }
}

.paper-consent-form {
  .file-upload-container {
    width: 800px;
  }

  .paper-consent-button-container {
    margin: 10px 0px 10px 0px;
  }

  .float-right {
    float: right;
  }

  .margin-bottom-10 {
    margin-bottom: 10px;
  }

  .margin-left-10 {
    margin-left: 10px;
  }

  .margin-bottom-5 {
    margin-bottom: 5px;
  }

  .margin-right-5 {
    margin-right: 5px;
  }

  .width-100 {
    width: 100%;
  }

  .container-fluid .padding-top-0 {
    padding-top: 0px;
  }

  .list-item-border-bottom {
    border-bottom: 1px solid grey;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .display-none {
    display: none;
  }
}

.linear-scale-container-top-title {
  .option-title-top {
    .mat-radio-label {
      display: flex;
      flex-direction: column;
      max-width: 75px;

      .mat-radio-label-content {
        padding-bottom: 5px;
        padding-right: 0;
        text-align: center;
      }
    }
  }
}

.multiple-checkboxes-container {
  .option {
    .mat-checkbox-layout {
      white-space: normal;
      word-break: break-word;
    }
  }
}

.question .related-question-container {
  border-left: 5px solid black;
  background-color: #f0f0f0;
}

.disabled-question {
  padding: 0;
  color: rgba(0, 0, 0, 0.38);
  cursor: pointer;
  pointer-events: none;
  user-select: none;

  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle,
  .mat-radio-outer-circle,
  .mat-checkbox-frame {
    border-color: rgba(0, 0, 0, 0.38);
  }

  .mat-radio-button.mat-primary .mat-radio-inner-circle,
  .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple,
  .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
  .mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: rgba(0, 0, 0, 0.38);
  }

  .mat-radio-label-content,
  .mat-form-field-infix {
    user-select: none;
  }

  .mat-checkbox-checked .mat-checkbox-frame {
    border-color: transparent;
  }
}

.base-dialog>.mat-dialog-container,
.submission-add-child-dialog>.mat-dialog-container,
.hna-needs-attention-dialog>.mat-dialog-container,
.hna-resolve-needs-attention-dialog>.mat-dialog-container {
  overflow: unset;
  padding: 0;
}

.hna-expansion-panel {
  .mat-expansion-panel-header {
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-top: 1px solid #cccccc;
  }

  .mat-expansion-panel-header.mat-expanded {
    border-bottom: 1px solid #cccccc;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

.ez-table-container {
  .mat-header-cell {
    &:last-of-type {
      padding-right: 10px;
    }

    .mat-form-field-wrapper {
      margin-top: 8px;
    }

    .mat-form-field-underline {
      background-color: white;
    }

    &.mat-column-yearGroup .mat-form-field,
    &.mat-column-class .mat-form-field {
      width: 90px;
    }

    &.mat-column-dateOfBirth .mat-form-field,
    &.mat-column-createdOn .mat-form-field {
      width: 90px;
    }

    .mat-form-field-label-wrapper {
      label span {
        color: white;
      }
    }
  }

  table .mat-sort-header-arrow {
    color: white;
  }
}

#right-pane-tabs {
  .mat-tab-body-wrapper {
    height: 100%;

    .mat-tab-body {
      position: absolute;
    }
  }

  .mat-tab-label {
    min-width: 70px;
  }

  .mat-card-header-text {
    width: 100%;
    margin: 0 0 0 16px;
  }
}

.form-manager-campaign-dropdown-tooltip {
  position: relative;
  top: 10px;
}

.fm-table-container {
  .fm-header-row-labels {
    th {
      .mat-form-field {
        label {
          color: #ffffff;
        }

        .mat-form-field-wrapper .mat-form-field-underline {
          background-color: #ffffff;
        }
      }

      .mat-select-value .mat-select-value-text span {
        color: #ffffff;
      }

      .mat-select-arrow {
        color: #ffffff;
      }
    }
  }
}

.children-page-paginator,
.queue-table-paginator {
  .mat-paginator-container {
    justify-content: flex-start;
  }
}

.view-last-report-card.mat-card {
  padding: 0;

  .mat-card-content {
    padding: 16px;
  }

  .action-buttons-container {
    margin: 0;
    background-color: #eaeaea;
    padding: 16px;
  }

  .mat-card-actions:last-child {
    padding-bottom: 16px;
  }
}

.questionnaire-results-dialog>.mat-dialog-container {
  padding: 0 24px;

  .mat-dialog-title {
    margin: 10px 0 10px;
  }

  .mat-dialog-actions {
    justify-content: flex-end;

    button {
      outline: none;
    }
  }
}

#growthchart {
  g[class$="Overweight-column"] {
    stroke-dasharray: 20px;
  }

  g[class$="VeryOverweight-column"] {
    stroke-dasharray: 40px;
  }

  g[class$="Underweight-column"] {
    stroke-dasharray: 8px;
  }

  g[class$="Child-column"] .c3-circles .c3-circle {
    opacity: 1 !important;
  }

  g[class$="Child-column"].c3-focused .c3-circles .c3-circle {
    opacity: 1 !important;
  }
}

.grid-four-columns {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.grid-two-columns {
  display: grid;
  grid-template-columns: 50% 50%;
}

.grid-three-columns {
  display: grid;
  grid-template-columns: 25% 25% 50% 0%;
}

.grid-span-two {
  grid-column-end: span 2;
}

.advance-search-panel {
  .panel-subtitle {
    margin: 15px;
    font-size: 16px;
    font-weight: bold;
  }

  .advance-search-row {
    .advance-search-col {
      margin: 0 15px;
    }
  }

  .advance-covid-details {
    align-items: baseline;
  }


  .warning-message-test-date {
    margin: 15px;
    font-size: 14px;
    font-weight: bold;
    color: red;
  }
}

.formly-checkbox {
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #1565c0;
  }

  .mat-checkbox-background {
    border-color: #1565c0;
  }
}

.add-edit-child-page-container {
  .edit-section-title {
    color: #707070;
    border-bottom: 2px solid #E1E1E1;
    margin: 20px 0 30px 0;
    display: flex;
    justify-content: space-between;
  }

  formly-dropdown-searchable {
    width: 90%;
  }

  formly-group {
    margin-right: 0;

    formly-field {
      width: 100%;
      padding-right: 0;

      formly-wrapper-mat-form-field,
      app-select-withremove,
      app-datepicker-notinfuture {
        width: 90%;
      }
    }
  }

  .child-is-adult-checkbox {
    .mat-checkbox-layout {
      flex-direction: row-reverse;

      .mat-checkbox-label {
        margin-right: 8px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .add-edit-child-page-container {
    formly-group {
      formly-field:not(:first-child) {
        padding-left: 30px;
      }
    }
  }
}

.column-visibility-dialog-container {
  formly-field {
    display: inline-flex;
    width: 50%;
  }

  formly-group {
    width: 100%;

    formly-field {
      width: 100%;
    }
  }

  .column {
    display: flex;
    flex-direction: column;

    .mat-form-field-wrapper .mat-form-field-infix {
      padding: 0;
    }
  }
}

.covid-mark-tested-dialog {
  @extend .base-dialog;

  width: 100%;
}

.covid-tests-results-dialog {
  @extend .base-dialog;

  width: 100%;
}

@media only screen and (min-width: 992px) {
  .covid-mark-tested-dialog {
    width: 832px;
  }

  .covid-tests-results-dialog {
    width: 832px;
  }
}


button:focus {
  outline: none;
}

.black-svg-icon {
  svg {
    fill: #000000;
  }
}

.test-svg-icon {
  svg {
    vertical-align: top;
  }

  &-yellow {
    svg {
      fill: #FF9D09;
    }
  }

  &-red {
    svg {
      fill: #EB6969;
    }
  }
}

.school-folder-template-column {
  .mat-sort-header-arrow {
    color: #FFFFFF;
  }
}

.field-long-text-value {
  p {
    margin: 0;

    img {
      max-width: 100%;
    }
  }
}

.fab-menu-svg-icon.mat-mini-fab {
  .mat-button-wrapper {
    padding: 0 0 5px 0;
  }
}

.hide-queue-table-paginator-arrows {
  .mat-focus-indicator {
    display: none;
  }
}

.operation-details {
  color: rgb(129, 129, 129);
  font-size: 12px;
}

.text-redhot {
  color: red;
}

.unconfirmedLabel {
  color: red;
  font-style: italic;
  font-size: 12px
}

.wrap-text {
  .mat-checkbox-inner-container {
    margin-top: 5px !important;
  }

  span {
    text-wrap: wrap;
  }
}

.padding-10 {
padding: 10px;
}

.padding-15 {
  padding: 15px;
  }
  
.margin-top-15 {
  margin-top: 15px;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-left-0 {
  margin-left: 0px;
}

.float-right {
  float: right;
}

.space-between {
  justify-content: space-between;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #2b542c;
}
.alert-info {
  background-color: #e3edf7;
  border-color: #c7e2ef;
  color: #31708f;
}
.alert-info hr {
  border-top-color: #b3d7e9;
}
.alert-info .alert-link {
  color: #245269;
}
.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #66512c;
}
.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
  color: #843534;
}

.width-100 {
  width: 100%;
}

.kpi-chart {
  margin-top: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  min-height: 500px;
  width: 100%;

  &svg{
    border-radius: 20px;

  }
}